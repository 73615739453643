import React, { useRef, useState, useEffect, useCallback } from 'react';
import './mrecruit_impl.css';
import {faq_list, recruit_list} from '../../commondata';

import MjdgameDesignBattle from '../mjdgameDesignerBattle/mjdgameDesignerBattle'
import MjdgameDesignConcept from '../mjdgameDesignerConcept/mjdgameDesignerConcept'
import MjdgameDesignLevel from '../mjdgameDesignerLevel/mjdgameDesignerLevel'
import Mjdgamedesignmsl from '../mjdgamedesignmsl/mjdgamedesignmsl'
import Mjdta from "../mjdta/mjdta"


const RecruitImpl = (props) => {

    const sizeHeaderH = 79;
    const sizeTopImgH = 1440;
    const sizeRecruitHeader = 178; // 위 아래 마진 포함
    const sizeFaqHeader = 326; // 위 아래 마진 포함
    const sizeTailLink = 214; // 위 마진 포함
    const sizeFooter = 413;
    const sizeRecruitItem = 157;
    const sizeFaqItem = 183;
    const recruitInnerW = 682;

    const [idxfaqOpened, setFaqOpenedIndex] = useState(-1);
    const [faqDimension, setFaqDimension] = useState({width:0, height:0});
    const [faqAnsDimension, setFaqAnsDimension] = useState({width:0, height:0});
    const [faqQusDimension, setFaqQusDimension] = useState({width:0, height:0});

    const [idxrecruitOpened, setRecruitOpenedIndex] = useState(-1);
    const [refRecruitTitles, setRefRecruitTitles] = useState([]);
    const [recruitInnerDeimension, setRecruitInnerDimension] = useState({width:0, height:0});
    const [openedItemScale, setOpenedItemScale] = useState(1.0);

    const onSetFaqOpenedIndex = useCallback((idx, isReset=false) => {
        if(isReset===false && idxfaqOpened>-1 && idxfaqOpened<idx)
        {
            const y = window.pageYOffset - (faqQusDimension.height + faqAnsDimension.height - 12);
            window.scrollTo(0, y);
        }

        setFaqOpenedIndex(isReset ? -1 : idx);
    })
    const onSetRecruitOpenedIndex = useCallback((idx) => {
        const nextIdx = (idx===idxrecruitOpened) ? -1 : idx;
        if(nextIdx>-1 && idxrecruitOpened>-1 && idxrecruitOpened<nextIdx)
        {
            const y = window.pageYOffset - (recruitInnerDeimension.height*openedItemScale + 40);
            window.scrollTo(0, y);
        }

        setRecruitOpenedIndex(nextIdx);
    })

    const reffaq = useRef();
    const reffaqans = useRef();
    const reffaqqus = useRef();
    const refrecruitdetail = useRef();
    const refrecruitinner = useRef();
    useEffect(() => {
        if(reffaq.current != null)
        {
            setFaqDimension({
                width: reffaq.current.scrollWidth,
                height: reffaq.current.scrollHeight
            })
        }

        if(reffaqans.current != null)
        {
            setFaqAnsDimension({
                width: reffaqans.current.scrollWidth,
                height: reffaqans.current.scrollHeight
            })
        }

        if(reffaqqus.current != null)
        {
            setFaqQusDimension({
                width: reffaqqus.current.scrollWidth,
                height: reffaqqus.current.scrollHeight
            })
        }
        
    }, [idxfaqOpened]);

    useEffect(() => {

        if(refrecruitinner.current != null)
        {
            setRecruitInnerDimension({
                width: refrecruitinner.current.scrollWidth,
                height: refrecruitinner.current.scrollHeight
            })

            const width = refrecruitinner.current.scrollWidth;
            const ratio = (recruitInnerW<width) ? recruitInnerW / width : 1.0;
            setOpenedItemScale(ratio);
        }

    }, [idxrecruitOpened]);

    const rezieRef = useCallback(() => {
        if(refRecruitTitles==null || refRecruitTitles.length!==recruit_list.length)
        {
            setRefRecruitTitles((trefs) => 
                Array(recruit_list.length).fill().map((_, i) => trefs[i] || React.createRef())
            );
        }
    })
    rezieRef();
    
    useEffect(() => {
        rezieRef();
    }, [recruit_list.length]);
    

    const recursivelyMod = (children) => {
        return React.Children.map(children, (childNode) => {
            
            if (typeof childNode === "string")
            {
                return childNode;
            }
            if (typeof childNode.props.children === "string")
            {
                return React.cloneElement(childNode, [], recursivelyMod(childNode.props.children));
            }

            if(typeof childNode.props.className === "string" && (childNode.props.className.indexOf("recruititem") > -1 || childNode.props.className.indexOf("faqitem") > -1) )
            {
                // 여기서는 아무것도 그리지 않음
                return ("");
            }
            else if(childNode.props.className === "recruitlist")
            {
                let detail_height = (idxrecruitOpened>-1) ? recruitInnerDeimension.height*openedItemScale : 0; // detail 공간
                const baseTop = sizeTopImgH + sizeRecruitHeader;
                let height = recruit_list.length*sizeRecruitItem/* 157(아이템갯수) */ + 212/* 68(빈공간) + 50(마진) + 104(버튼) */ + detail_height;

                const iter = recruit_list.map((item, index) => {

                    const DetailContents = () => {
                        
                        const ch = recruitInnerDeimension.height*openedItemScale + 136; // 50(버튼사이) + 66(버튼) + 20(위 공백)
                        const styled = {
                            position: 'relative',
                            top: `${index*sizeRecruitItem+176}px`,
                            height: `${ch}px`
                        };

                        if(index === idxrecruitOpened)
                        {
                            const left = (recruitInnerW - recruitInnerDeimension.width) * openedItemScale / 2.0;
                            
                            return (
                                <div className="recruitexpanded" style={styled}>
                                    <div className="recruititemdetailmod" style={{hright:'100%'}}>
                                    <div className="detailcontents" ref={refrecruitinner} style={{left: `${left}px`, align:'left', transform: `scale(${openedItemScale})`, whiteSpace: 'pre-line', wordBreak: 'break-all'}}>
                                        {index===0 && <MjdgameDesignBattle />}
                                        {index===1 && <MjdgameDesignLevel />}
                                        {index===2 && <MjdgameDesignConcept />}
                                        {index===3 && <Mjdgamedesignmsl />}
                                        {index===4 && <Mjdta />}
                                    </div>
                                        <svg preserveAspectRatio="none" viewBox="0 -0.5 681.189453125 1" className="bottomline" style={{top: `${(recruitInnerDeimension.height+40)*openedItemScale}px`}}><path d="M 0 0 L 681.1895141601562 0"  /></svg>
                                    </div>
                                </div>
                            );
                        }
                        return ("");
                    };
                    const AddNewIcon = () => {
                        if(item.isNew)
                        {
                            const elew = (refRecruitTitles[index]!=null && refRecruitTitles[index].current!=null) ? refRecruitTitles[index].current.scrollWidth : 0;

                            return (
                                <div className="newicon" style={{left: `${elew+21}px`}}>
                                    <svg preserveAspectRatio="none" viewBox="0 0 39 39" className="circleicon"><path d="M 19.5 0 C 30.26955032348633 0 39 8.730448722839355 39 19.5 C 39 30.26955032348633 30.26955032348633 39 19.5 39 C 8.730448722839355 39 0 30.26955032348633 0 19.5 C 0 8.730448722839355 8.730448722839355 0 19.5 0 Z"  /></svg>
                                    <div className="textn">N</div>
                                </div>
                            )
                        }
                        return ("");
                    }

                    const pos_item = (idxrecruitOpened<0 || index<=idxrecruitOpened) ? 0 : detail_height + 41;
                    const style_item = {
                        top: `${pos_item + 41/* title 밑에 마진(0번째 인덱스) */ + index*sizeRecruitItem}px`,
                        cursor: 'pointer'
                    }
                    return (
                        <div key={index} height={height}>
                        <div className="recruititem" style={style_item} onClick={() => onSetRecruitOpenedIndex(index) }>
                            <div className="tasktitle">
                                <div className="texttitle" ref={refRecruitTitles[index]}>{item.tasktitle}</div>
                            </div>
                            <div className="jobgroup">
                                <div className="texttitle">{item.jobgroup}</div>
                            </div>
                            <svg preserveAspectRatio="none" viewBox="0 -0.5 682 1" className="bottomline"><path d="M 0 0 L 682 0"  /></svg>
                            <AddNewIcon />
                        </div>
                        <DetailContents />
                        </div>
                    )
                    
                })
                // 여기서 array 갯수에 따라서 iteration 돌면서 그려야 함

                const styled = {
                    top: `${baseTop}px`,
                    height: `${height}px`
                };
                let link_url = (idxrecruitOpened>-1 && idxrecruitOpened<recruit_list.length) ? recruit_list[idxrecruitOpened].linkto : "https://career.thepinkfong.com/jobs";

                return (
                    <div className="recruitlistmod" style={styled}>
                    <a href={link_url} target="_Blank"><div className="buttonapply">
                        <div className="bgrect"></div>
                        <div className="textapply">지원하러 가기</div>
                    </div></a>
                    <svg preserveAspectRatio="none" viewBox="0 -0.5 682 1" className="upperline"><path d="M 0 0 L 682 0"  /></svg>
                        {iter}
                    </div>
                );
            }
            else if(childNode.props.className === "faqlist")
            {
                // 여기서 array 갯수에 따라서 iteration 돌면서 그려야 함
                const added = (idxrecruitOpened>-1) ? recruitInnerDeimension.height*openedItemScale : 0;
                const baseTop = sizeTopImgH + sizeRecruitHeader + sizeFaqHeader + recruit_list.length*sizeRecruitItem + 30;
                let accH = 0;
                
                const iter = faq_list.map((item, index) => {

                    if(index === idxfaqOpened)
                    {
                        const ch = faqQusDimension.height + faqAnsDimension.height + 121;
                        const itemstyled = {
                            top: `${sizeFaqHeader-241+49+accH}px`,
                            height: `${ch+1}px`,
                            cursor: 'pointer'
                        }; // 50은 Topline에서 Q 시작부분 마진
                        accH += ch + 50;
                        
                        return (
                            <div className="faqitemopened" ref={reffaq} key={index} style={itemstyled} onClick={() => onSetFaqOpenedIndex(index, true)}>
                                <svg preserveAspectRatio="none" viewBox="0 -1 682 2" style={{top: `${ch-1}px`}} className="bottomline"><path d="M 0 0 L 682 0"  /></svg>
                                <div className="faqdetail">
                                    <div className="textdetail" ref={reffaqans} style={{whiteSpace: 'pre-line', wordBreak: 'break-all'}}>{item.answer}</div>
                                </div>
                                <div className="faqtitleopened">
                                    <div className="texttitle" ref={reffaqqus} style={{whiteSpace: 'pre-line', wordBreak: 'break-all'}}>{item.title}</div>
                                </div>
                                <div className="iconqopened">
                                    <svg preserveAspectRatio="none" viewBox="0 0 68 68" className="circleicon"><path d="M 34 0 C 52.77767944335938 0 68 15.22231864929199 68 34 C 68 52.77767944335938 52.77767944335938 68 34 68 C 15.22231864929199 68 0 52.77767944335938 0 34 C 0 15.22231864929199 15.22231864929199 0 34 0 Z"  /></svg>
                                    <div className="textq">Q</div>
                                </div>
                                <div className="iconaopened">
                                    <svg preserveAspectRatio="none" viewBox="0 0 68 69" className="circleicon"><path d="M 34 0 C 52.77767944335938 0 68 15.44617652893066 68 34.5 C 68 53.55382537841797 52.77767944335938 69 34 69 C 15.22231864929199 69 0 53.55382537841797 0 34.5 C 0 15.44617652893066 15.22231864929199 0 34 0 Z"  /></svg>
                                    <div className="texta">A</div>
                                </div>
                            </div>
                        )
                    }
                    else
                    {
                        const ch = sizeFaqItem;
                        const itemstyled = {
                            top: `${sizeFaqHeader-241+accH}px`,
                            height: `${ch+1}px`,
                            cursor: 'pointer'
                        };

                        accH += ch;
                        return (
                            <div className="faqitemmod" key={index} style={itemstyled} onClick={() => onSetFaqOpenedIndex(index)}>
                                <svg preserveAspectRatio="none" viewBox="0 -1 682 2" className="bottomline"><path d="M 0 0 L 682 0"  /></svg>
                                <div className="faqtitle">
                                    <div className="txttitle" ref={reffaqqus} style={{whiteSpace: 'pre-line', wordBreak: 'break-all'}}>{item.title}</div>
                                </div>
                                <div className="iconq">
                                    <svg preserveAspectRatio="none" viewBox="0 0 68 68" className="circleicon"><path d="M 34 0 C 52.77767944335938 0 68 15.22231864929199 68 34 C 68 52.77767944335938 52.77767944335938 68 34 68 C 15.22231864929199 68 0 52.77767944335938 0 34 C 0 15.22231864929199 15.22231864929199 0 34 0 Z"  /></svg>
                                    <div className="txtquestion">Q</div>
                                </div>
                                <div className="itemrect"></div>
                            </div>
                        )
                    }
                })
                
                const styled = {
                    top: `${baseTop+added}px`,
                    height: `${faq_list.length*sizeFaqItem + sizeFaqHeader-241}px`
                };
                return (
                    <div className="faqlistmod" style={styled}>
                        <div className="texttitle">자주 하는 질문</div>
                        <svg preserveAspectRatio="none" viewBox="0 -1 682 2" className="upperline"><path d="M 0 0 L 682 0"  /></svg>
                        {iter}
                    </div>
                );
            }
            else if(childNode.props.className === "contentbody")
            {
                let added = (idxfaqOpened>-1) ? faqQusDimension.height + faqAnsDimension.height + 121 : faqQusDimension.height + 92;
                added += (idxrecruitOpened>-1) ? recruitInnerDeimension.height*openedItemScale : 0;
                let height = sizeTopImgH + sizeRecruitHeader + sizeFaqHeader + sizeTailLink;
                height += faq_list.length*sizeFaqItem + recruit_list.length*sizeRecruitItem;
                
                const styled = {
                    height: `${height+added}px`,
                };
                
                return React.cloneElement(childNode, {style: styled}, recursivelyMod(childNode.props.children));
            }
            else if(childNode.props.className === "footer")
            {
                let added = (idxfaqOpened>-1) ? faqQusDimension.height + faqAnsDimension.height + 121 : faqQusDimension.height + 92;
                added += (idxrecruitOpened>-1) ? recruitInnerDeimension.height*openedItemScale : 0;
                let height = sizeHeaderH + sizeTopImgH + sizeRecruitHeader + sizeFaqHeader + sizeTailLink + 50;
                height += faq_list.length*sizeFaqItem + recruit_list.length*sizeRecruitItem;

                const styled = {
                    top: `${height+added}px`,
                };
                return React.cloneElement(childNode, {style: styled}, recursivelyMod(childNode.props.children));
            }
            else if(childNode.props.className === "seperateline")
            {
                const added = (idxrecruitOpened>-1) ? recruitInnerDeimension.height*openedItemScale : 0;
                const baseTop = sizeTopImgH + sizeRecruitHeader + (sizeFaqHeader-52) + recruit_list.length*sizeRecruitItem;

                const styled = {
                    top: `${baseTop+added}px`,
                };
                return React.cloneElement(childNode, {style: styled}, recursivelyMod(childNode.props.children));
            }
            else if(childNode.props.className === "mrecruit")
            {
                let added = (idxfaqOpened>-1) ? faqQusDimension.height + faqAnsDimension.height + 121 : faqQusDimension.height + 92;
                added += (idxrecruitOpened>-1) ? recruitInnerDeimension.height*openedItemScale : 0;
                let height = sizeHeaderH + sizeTopImgH + sizeRecruitHeader + sizeFaqHeader + sizeTailLink + 50;
                height += faq_list.length*sizeFaqItem + recruit_list.length*sizeRecruitItem;

                const top = height+added+413; // 231 = footer size
                const styled = {
                    height: `${top}px`
                };
                return React.cloneElement(childNode, {style: styled}, recursivelyMod(childNode.props.children));
            }
            
            return React.cloneElement(childNode, [], recursivelyMod(childNode.props.children));
        })
    }

    return (
        recursivelyMod(props.children)
    );
}

export default RecruitImpl;